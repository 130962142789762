// The z-index of most of the current screen content.
// If you want something "above", do +1.
export const baseIndex = Symbol('baseIndex');

// Set it to something like {kind: 'positive'} and all buttons will be positive by default. ✌️
export const buttonAttrs = Symbol('buttonAttrs');

// Set to true and filter pills will show icons.
export const filterPillIconVisible = Symbol('filterPillIconsVisible');

// Used by popovers
export const popoverArrow = Symbol('popover-arrow');

// Common tables
export const cellVariant = Symbol('common-table__cell-variant');
export const mobileTableSplit = Symbol('common-table__mobile-table-split');

// Whether something is rendered on the regular background or on the accent color'
// 'bg' | 'accent'
export const surface = Symbol('surface');

// The current data context.
// 'post', 'list', 'account', 'platform', 'accountGroup', 'group'
export const dataContext = Symbol('dataContext');

// Device pixel ratio for canvas elements in reports
export const devicePixelRatio = Symbol('devicePixelRatio');
